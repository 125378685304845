define("discourse/plugins/discourse-encrypt/discourse/services/encrypt-widget-store", ["exports", "@ember/service", "@ember-compat/tracked-built-ins"], function (_exports, _service, _trackedBuiltIns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class EncryptWidgetStore extends _service.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "widgets", new _trackedBuiltIns.TrackedArray());
    }
    add(widget) {
      this.widgets.push(widget);
    }
    reset() {
      this.widgets.length = 0;
    }
  }
  _exports.default = EncryptWidgetStore;
});